// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../common/util.bs.js";
import * as $$Array from "@rescript/std/lib/es6/array.js";
import * as $$String from "@rescript/std/lib/es6/string.js";
import * as Js_string from "@rescript/std/lib/es6/js_string.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Caml_array from "@rescript/std/lib/es6/caml_array.js";
import * as Pervasives from "@rescript/std/lib/es6/pervasives.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Belt_SortArray from "@rescript/std/lib/es6/belt_SortArray.js";
import * as CategoriesConf from "../config/CategoriesConf.bs.js";

function sortCardsByDenominations(d1, d2) {
  var match = Pervasives.float_of_string_opt(d1.value);
  var match$1 = Pervasives.float_of_string_opt(d2.value);
  if (match !== undefined && match$1 !== undefined && match > match$1) {
    return 1;
  } else {
    return 0;
  }
}

function getDenominationSymbol(d) {
  return Util.CurrencyUtils.getDenominationSymbol(d.valueCurrency);
}

function getPriceDenominationSymbol(d) {
  return Util.CurrencyUtils.getDenominationSymbol(d.priceCurrency);
}

function getPriceRange(sortedArray) {
  var getValueAndCurrency = function (i) {
    return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.get(sortedArray, i), (function (d) {
                      return d.valueCurrency + (" " + (d.denominationSymbol + String(Number(d.value))));
                    })), "");
  };
  var n = sortedArray.length;
  if (n !== 0) {
    if (n !== 1) {
      return getValueAndCurrency(0) + (" - " + getValueAndCurrency(n - 1 | 0));
    } else {
      return Belt_Option.getWithDefault(Belt_Option.map(Belt_Array.get(sortedArray, 0), (function (d) {
                        return d.valueCurrency + (" " + (d.denominationSymbol + String(Number(d.value))));
                      })), "");
    }
  } else {
    return "";
  }
}

function skuDenToCardDen(d) {
  return {
          code: d.code,
          value: d.value,
          valueCurrency: d.valueCurrency,
          denominationSymbol: Util.CurrencyUtils.getDenominationSymbol(d.valueCurrency),
          price: d.price,
          priceCurrency: d.priceCurrency,
          priceDenominationSymbol: Util.CurrencyUtils.getDenominationSymbol(d.priceCurrency)
        };
}

function makeCategory(c) {
  return {
          name: c.name,
          slug: c.slug,
          position: c.position,
          order: c.order,
          skuPosition: c.skuPosition
        };
}

function fixCategories(categories) {
  Belt_Array.reduce(categories, false, (function (hasCountry, cat) {
          if (hasCountry) {
            return true;
          } else {
            return $$Array.mem(cat.name, CategoriesConf.countriesWithCountryCat);
          }
        }));
  return Belt_Array.map(categories, makeCategory);
}

function makeAlert(a) {
  return {
          title: a.title,
          messageMarkdown: a.messageMarkdown,
          level: a.level,
          priority: a.priority
        };
}

function fixAlerts(alerts) {
  return Belt_Array.map(alerts, makeAlert);
}

function makeTheme(t) {
  return {
          uid: t.uid,
          code: t.code,
          image: t.image
        };
}

function fixThemes(themes) {
  return Belt_Array.map(themes, makeTheme);
}

function makeTemplate(t) {
  return {
          uid: t.uid,
          imageFrame: t.imageFrame,
          imagePreview: t.imagePreview
        };
}

function fixTemplates(templates) {
  return Belt_Array.map(templates, makeTemplate);
}

function makePromo(p) {
  return {
          uid: p.uid,
          title: p.title,
          enabled: p.enabled,
          promoText: p.promoText,
          terms: p.terms
        };
}

function fixPromos(promos) {
  return Belt_Array.map(promos, makePromo);
}

function makeSlug(backendSlug) {
  return $$String.lowercase_ascii(backendSlug) + "-gift-card";
}

function makeMerchant(m) {
  return {
          terms: m.terms
        };
}

function makeImage(themes) {
  if (themes.length !== 0) {
    return Caml_array.get(themes, 0).image;
  } else {
    return "";
  }
}

var emptyPresentation = {
  codename: "",
  themes: [],
  audience: [],
  interest: [],
  occasion: [],
  locationAu: [],
  locationNz: [],
  locationUk: [],
  locationUs: []
};

function skuToCard(sku) {
  var sortedPrices = Belt_SortArray.stableSortBy(Belt_Array.map(sku.denominations, skuDenToCardDen), sortCardsByDenominations);
  return {
          uid: sku.uid,
          imgUrl: makeImage(sku.themes),
          name: sku.name,
          searchableTerms: sku.searchableTerms,
          similarTerms: sku.similarTerms,
          displayName: sku.displayName,
          priceRange: getPriceRange(sortedPrices),
          denominations: sortedPrices,
          templates: Belt_Array.map(sku.templates, makeTemplate),
          themes: Belt_Array.map(sku.themes, makeTheme),
          promos: Belt_Array.map(sku.promos, makePromo),
          description: sku.description,
          longDescription: Belt_Option.getWithDefault(Caml_option.null_to_opt(sku.longDescription), ""),
          tagline: Belt_Option.getWithDefault(Caml_option.null_to_opt(sku.tagline), ""),
          descriptionMarkdown: sku.descriptionMarkdown,
          isSwappable: sku.isSwappable,
          slug: $$String.lowercase_ascii(sku.slug) + "-gift-card",
          code: sku.code,
          categories: fixCategories(sku.categories),
          expiryTermDisplay: sku.expiryTermDisplay,
          alerts: Belt_Array.map(sku.alerts, makeAlert),
          isPbCategoryHidden: sku.isPbCategoryHidden,
          isPcCategoryHidden: sku.isPcCategoryHidden,
          layerEnabled: Belt_Option.getWithDefault(Caml_option.null_to_opt(sku.layerEnabled), false),
          merchant: {
            terms: sku.merchant.terms
          },
          cmsId: sku.cmsId,
          createdAt: sku.createdAt,
          presentation: {
            codename: sku.cmsId,
            themes: [],
            audience: [],
            interest: [],
            occasion: [],
            locationAu: [],
            locationNz: [],
            locationUk: [],
            locationUs: []
          }
        };
}

var emptyCard_denominations = [];

var emptyCard_templates = [];

var emptyCard_themes = [];

var emptyCard_promos = [];

var emptyCard_categories = [];

var emptyCard_alerts = [];

var emptyCard_merchant = {
  terms: ""
};

var emptyCard = {
  uid: "",
  imgUrl: "",
  name: "",
  searchableTerms: "",
  similarTerms: "",
  displayName: "",
  priceRange: "",
  denominations: emptyCard_denominations,
  templates: emptyCard_templates,
  themes: emptyCard_themes,
  promos: emptyCard_promos,
  description: "",
  longDescription: "",
  tagline: "",
  descriptionMarkdown: "",
  isSwappable: false,
  slug: "",
  code: "",
  categories: emptyCard_categories,
  expiryTermDisplay: "",
  alerts: emptyCard_alerts,
  isPbCategoryHidden: false,
  isPcCategoryHidden: false,
  layerEnabled: false,
  merchant: emptyCard_merchant,
  cmsId: "",
  createdAt: "",
  presentation: emptyPresentation
};

var Card = {
  sortCardsByDenominations: sortCardsByDenominations,
  getDenominationSymbol: getDenominationSymbol,
  getPriceDenominationSymbol: getPriceDenominationSymbol,
  getPriceRange: getPriceRange,
  skuDenToCardDen: skuDenToCardDen,
  makeCategory: makeCategory,
  fixCategories: fixCategories,
  makeAlert: makeAlert,
  fixAlerts: fixAlerts,
  makeTheme: makeTheme,
  fixThemes: fixThemes,
  makeTemplate: makeTemplate,
  fixTemplates: fixTemplates,
  makePromo: makePromo,
  fixPromos: fixPromos,
  makeSlug: makeSlug,
  makeMerchant: makeMerchant,
  makeImage: makeImage,
  emptyPresentation: emptyPresentation,
  skuToCard: skuToCard,
  emptyCard: emptyCard
};

function rewriteUrl(url) {
  return Js_string.replaceByRe(/https:\/\/((?!go\.).)*prezzee.(com|co|uk).*?\//g, process.env.NEXT_PUBLIC_PREZZEE_SERVER_URL, url);
}

function bannerDataToBanner(b) {
  return {
          uid: b.uid,
          name: b.name,
          url: rewriteUrl(b.url),
          text: b.text,
          textColour: b.textColour,
          backgroundColour: b.backgroundColour,
          buttonText: b.buttonText,
          prefixText: b.prefixText,
          fontawesomeIconClass: b.fontawesomeIconClass
        };
}

function slideDataToSlide(s) {
  return {
          uid: s.uid,
          name: s.name,
          buttonBackgroundColour: s.buttonBackgroundColour,
          buttonBorderColour: s.buttonBorderColour,
          buttonTextColour: s.buttonTextColour,
          buttonBackgroundColourHover: s.buttonBackgroundColourHover,
          buttonBorderColourHover: s.buttonBorderColourHover,
          buttonTextColourHover: s.buttonTextColourHover,
          backgroundImage: s.backgroundImage,
          leftImage: s.leftImage,
          centeredImage: Caml_option.null_to_opt(s.centeredImage),
          titleColour: s.titleColour,
          titleShadowColour: s.titleShadowColour,
          enableTitleShadow: s.enableTitleShadow,
          title: s.title,
          subtitle: s.subtitle,
          subtitleColour: s.subtitleColour,
          buttonVimeoVideoId: s.buttonVimeoVideoId,
          buttonShowVideoPopup: s.buttonShowVideoPopup,
          buttonUrl: rewriteUrl(s.buttonUrl),
          buttonLabel: s.buttonLabel
        };
}

function homePageDataToHomePageSlide(h) {
  return {
          banner: Belt_Option.map(Caml_option.null_to_opt(h.banner), bannerDataToBanner),
          slides: Belt_Array.map(h.slides, slideDataToSlide)
        };
}

var HomePageSlide = {
  rewriteUrl: rewriteUrl,
  bannerDataToBanner: bannerDataToBanner,
  slideDataToSlide: slideDataToSlide,
  homePageDataToHomePageSlide: homePageDataToHomePageSlide
};

var MenuItem = {};

var SideBar = {
  MenuItem: MenuItem
};

var $$Notification = {};

var ProductDescription = {};

function toOpt(prim) {
  if (prim === null) {
    return ;
  } else {
    return Caml_option.some(prim);
  }
}

function getCardDenominationSymbol(d) {
  return Util.CurrencyUtils.getDenominationSymbol(d.valueCurrency);
}

function getCardOriginalBalanceDenominationSymbol(d) {
  return Util.CurrencyUtils.getDenominationSymbol(d.originalBalanceCurrency);
}

function getCardBalanceDenominationSymbol(d) {
  return Util.CurrencyUtils.getDenominationSymbol(d.balanceCurrency);
}

function getCardDefaultBalance(d) {
  return Util.DefaultBalance.getDefaultBalance(Caml_option.null_to_opt(d.balance));
}

function giftDataMediaProviderDetails(t) {
  return {
          videoId: t.videoId,
          accountId: t.accountId,
          playerId: t.playerId
        };
}

function giftDataMediaToMedia(t) {
  return {
          uid: t.uid,
          mediaType: t.mediaType,
          provider: t.provider,
          providerDetails: giftDataMediaProviderDetails(t.providerDetails)
        };
}

function optionGiftToWalletGift(giftOpt) {
  if (giftOpt === undefined) {
    return ;
  }
  if (giftOpt.TAG === /* CardId */0) {
    return ;
  }
  var g = giftOpt._0;
  return {
          uid: g.uid,
          status: g.status,
          regifted: g.regifted,
          message: g.message,
          recipientName: g.recipientName,
          recipientEmail: g.recipientEmail,
          unwrappingAuthFlowV2: g.unwrappingAuthFlowV2,
          senderName: Caml_option.null_to_opt(g.senderName),
          senderEmail: g.senderEmail,
          senderLogoUrl: g.senderLogoUrl,
          theme: {
            uid: g.theme.uid,
            thumbImage: g.theme.thumbImage,
            squareIcon: g.theme.squareIcon,
            textureImage: g.theme.textureImage,
            keyColour: g.theme.keyColour,
            textColour: g.theme.textColour
          },
          media: Belt_Array.map(g.media, giftDataMediaToMedia)
        };
}

function cardDataToWalletCard(c) {
  return {
          themeUid: c.themeUid,
          themeImage: c.themeImage,
          themekeyColour: c.themekeyColour,
          themeTextColour: c.themeTextColour,
          value: c.value,
          originalBalance: c.originalBalance,
          valueCurrency: c.valueCurrency,
          denominationSymbol: Util.CurrencyUtils.getDenominationSymbol(c.valueCurrency),
          originalBalanceCurrency: c.originalBalanceCurrency,
          originalBalanceDenominationSymbol: Util.CurrencyUtils.getDenominationSymbol(c.originalBalanceCurrency),
          balance: getCardDefaultBalance(c),
          balanceCurrency: c.balanceCurrency,
          balanceDenominationSymbol: Util.CurrencyUtils.getDenominationSymbol(c.balanceCurrency),
          expiry: Caml_option.null_to_opt(c.expiry),
          isExpired: c.isExpired,
          balanceUpdatedAt: Caml_option.null_to_opt(c.balanceUpdatedAt),
          notes: c.notes,
          uid: c.uid,
          productType: c.productType,
          cardLabel: c.cardLabel,
          cardPin: Caml_option.null_to_opt(c.cardPin),
          cardId: c.cardId,
          number: c.number,
          barcodeNumber: c.barcodeNumber,
          instoreNumber: c.instoreNumber,
          onlineNumber: c.onlineNumber,
          pin: Caml_option.null_to_opt(c.pin),
          pinFieldName: c.pinFieldName,
          cardBarcodeFormat: c.cardBarcodeFormat,
          cardLogo: c.cardLogo,
          merchantTerms: Caml_option.null_to_opt(c.merchantTerms),
          status: c.status,
          subtype: Caml_option.null_to_opt(c.subtype),
          updatedAt: c.updatedAt,
          activatedAt: c.activatedAt,
          createdAt: c.createdAt,
          receivedAt: c.receivedAt,
          gift: undefined,
          isSwapCard: c.isSwapCard,
          isSchemeCard: c.isSchemeCard,
          swapCard: c.swapCard,
          donation: c.donation,
          supportsRegift: c.supportsRegift,
          supportsBalanceCheck: c.supportsBalanceCheck,
          supportsAppleWalletExport: c.supportsAppleWalletExport,
          supportsGoogleWalletExport: c.supportsGoogleWalletExport,
          receiptText: Caml_option.null_to_opt(c.receiptText),
          receiptLogoTop: Caml_option.null_to_opt(c.receiptLogoTop),
          redemptionUrl: Caml_option.null_to_opt(c.redemptionUrl),
          balanceCheckUrl: Caml_option.null_to_opt(c.balanceCheckUrl),
          howToUse: Caml_option.null_to_opt(c.howToUse),
          skuDisplayName: c.skuDisplayName,
          skuUid: c.skuUid,
          pdfUrl: Caml_option.null_to_opt(c.pdfUrl),
          defaultProductCode: Caml_option.null_to_opt(c.defaultProductCode),
          defaultProductThemeCode: Caml_option.null_to_opt(c.defaultProductThemeCode),
          defaultSkuUid: Caml_option.null_to_opt(c.defaultSkuUid)
        };
}

var WalletCard = {
  toOpt: toOpt,
  getCardDenominationSymbol: getCardDenominationSymbol,
  getCardOriginalBalanceDenominationSymbol: getCardOriginalBalanceDenominationSymbol,
  getCardBalanceDenominationSymbol: getCardBalanceDenominationSymbol,
  getCardDefaultBalance: getCardDefaultBalance,
  giftDataMediaProviderDetails: giftDataMediaProviderDetails,
  giftDataMediaToMedia: giftDataMediaToMedia,
  optionGiftToWalletGift: optionGiftToWalletGift,
  cardDataToWalletCard: cardDataToWalletCard
};

var BoardMember = {};

var AboutSlide = {};

function toOpt$1(prim) {
  if (prim === null) {
    return ;
  } else {
    return Caml_option.some(prim);
  }
}

function getDenominationSymbol$1(d) {
  return Util.CurrencyUtils.getDenominationSymbol(d.valueCurrency);
}

function getDenominationSymbolForHistoryCard(d) {
  return Util.CurrencyUtils.getDenominationSymbol(d.valueCurrency);
}

function getDenominationSymbolForFailedCard(d) {
  return Util.CurrencyUtils.getDenominationSymbol(d.valueCurrency);
}

function getOriginalBalanceDenominationSymbol(d) {
  return Util.CurrencyUtils.getDenominationSymbol(d.originalBalanceCurrency);
}

function getBalanceDenominationSymbol(d) {
  return Util.CurrencyUtils.getDenominationSymbol(d.balanceCurrency);
}

function getDefaultBalance(d) {
  return Util.DefaultBalance.getDefaultBalance(Caml_option.null_to_opt(d.balance));
}

function giftDataToCard(c) {
  return {
          themeUid: c.themeUid,
          themeImage: c.themeImage,
          themekeyColour: c.themekeyColour,
          themeTextColour: c.themeTextColour,
          value: c.value,
          originalBalance: c.originalBalance,
          valueCurrency: c.valueCurrency,
          denominationSymbol: Util.CurrencyUtils.getDenominationSymbol(c.valueCurrency),
          originalBalanceCurrency: c.originalBalanceCurrency,
          originalBalanceDenominationSymbol: Util.CurrencyUtils.getDenominationSymbol(c.originalBalanceCurrency),
          balance: getDefaultBalance(c),
          balanceCurrency: c.balanceCurrency,
          balanceDenominationSymbol: Util.CurrencyUtils.getDenominationSymbol(c.balanceCurrency),
          expiry: Caml_option.null_to_opt(c.expiry),
          isExpired: c.isExpired,
          balanceUpdatedAt: Caml_option.null_to_opt(c.balanceUpdatedAt),
          notes: c.notes,
          uid: c.uid,
          productType: c.productType,
          cardLabel: c.cardLabel,
          cardPin: Caml_option.null_to_opt(c.cardPin),
          cardId: c.cardId,
          number: c.number,
          barcodeNumber: c.barcodeNumber,
          barcodeLabel: c.barcodeLabel,
          instoreNumber: c.instoreNumber,
          onlineNumber: c.onlineNumber,
          pin: Caml_option.null_to_opt(c.pin),
          pinFieldName: c.pinFieldName,
          cardBarcodeFormat: c.cardBarcodeFormat,
          cardLogo: c.cardLogo,
          merchantTerms: Caml_option.null_to_opt(c.merchantTerms),
          status: c.status,
          updatedAt: c.updatedAt,
          activatedAt: c.activatedAt,
          createdAt: c.createdAt,
          receivedAt: Belt_Option.getWithDefault(Caml_option.null_to_opt(c.receivedAt), ""),
          gift: Caml_option.null_to_opt(c.gift),
          isSwapCard: c.isSwapCard,
          isSchemeCard: c.isSchemeCard,
          subtype: Caml_option.null_to_opt(c.subtype),
          swapCard: c.swapCard,
          donation: c.donation,
          supportsRegift: c.supportsRegift,
          supportsBalanceCheck: c.supportsBalanceCheck,
          supportsAppleWalletExport: c.supportsAppleWalletExport,
          supportsGoogleWalletExport: c.supportsGoogleWalletExport,
          receiptText: Caml_option.null_to_opt(c.receiptText),
          receiptLogoTop: Caml_option.null_to_opt(c.receiptLogoTop),
          redemptionUrl: Caml_option.null_to_opt(c.redemptionUrl),
          balanceCheckUrl: Caml_option.null_to_opt(c.balanceCheckUrl),
          howToUse: Caml_option.null_to_opt(c.howToUse),
          skuDisplayName: c.skuDisplayName,
          skuUid: c.skuUid,
          pdfUrl: Caml_option.null_to_opt(c.pdfUrl),
          defaultProductCode: Caml_option.null_to_opt(c.defaultProductCode),
          defaultProductThemeCode: Caml_option.null_to_opt(c.defaultProductThemeCode),
          defaultSkuUid: Caml_option.null_to_opt(c.defaultSkuUid)
        };
}

function giftDataThemeToTheme(t) {
  return {
          uid: t.uid,
          thumbImage: t.thumbImage,
          squareIcon: t.squareIcon,
          textureImage: t.textureImage,
          keyColour: t.keyColour,
          textColour: t.textColour
        };
}

function giftDataMediaProviderDetails$1(t) {
  return {
          videoId: t.videoId,
          accountId: t.accountId,
          playerId: t.playerId
        };
}

function giftDataMediaToMedia$1(t) {
  return {
          uid: t.uid,
          mediaType: t.mediaType,
          provider: t.provider,
          providerDetails: giftDataMediaProviderDetails$1(t.providerDetails)
        };
}

function giftDataToHistoryCard(c) {
  return {
          cardLabel: c.cardLabel,
          themeImage: c.themeImage,
          balance: c.balance,
          balanceCurrency: c.balanceCurrency,
          value: c.value,
          valueCurrency: c.valueCurrency,
          denominationSymbol: Util.CurrencyUtils.getDenominationSymbol(c.valueCurrency),
          originalBalance: c.originalBalance,
          originalBalanceCurrency: c.originalBalanceCurrency
        };
}

function giftDataToHistoryGiftCard(t) {
  return {
          uid: t.uid,
          status: t.status,
          card: giftDataToHistoryCard(t.card),
          theme: giftDataThemeToTheme(t.theme)
        };
}

function giftDataSkuThemeToSkuTheme(t) {
  return {
          uid: t.uid,
          code: t.code,
          image: t.image,
          keyColour: t.keyColour,
          textColour: t.textColour
        };
}

function giftDataToFailedCard(c) {
  return {
          orderNumber: c.orderNumber,
          value: c.value,
          valueCurrency: c.valueCurrency,
          denominationSymbol: Util.CurrencyUtils.getDenominationSymbol(c.valueCurrency),
          skuTheme: giftDataSkuThemeToSkuTheme(c.skuTheme),
          skuDisplayName: c.skuDisplayName,
          uid: c.uid
        };
}

function giftDataToGiftCard(t) {
  return {
          uid: t.uid,
          status: t.status,
          regifted: t.regifted,
          message: t.message,
          recipientName: t.recipientName,
          recipientEmail: t.recipientEmail,
          senderName: Caml_option.null_to_opt(t.senderName),
          senderEmail: t.senderEmail,
          senderType: t.senderType,
          senderLogoUrl: t.senderLogoUrl,
          card: giftDataToCard(t.card),
          theme: giftDataThemeToTheme(t.theme),
          media: Belt_Array.map(t.media, giftDataMediaToMedia$1),
          exchangeHistory: Belt_Option.map(t.exchangeHistory, (function (h) {
                  return Belt_Array.map(h, giftDataToHistoryGiftCard);
                })),
          exchangeHistoryFailure: Belt_Option.map(t.exchangeHistoryFailure, (function (h) {
                  return Belt_Array.map(h, giftDataToFailedCard);
                })),
          giphyId: t.giphyId,
          giphyUrl: t.giphyUrl,
          canAddToWallet: t.canAddToWallet,
          unwrappedAt: t.unwrappedAt,
          pinStatus: t.pinStatus,
          unwrappingAuthFlowV2: t.unwrappingAuthFlowV2,
          forcedSignIn: t.forcedSignIn
        };
}

var GiftCard = {
  toOpt: toOpt$1,
  getDenominationSymbol: getDenominationSymbol$1,
  getDenominationSymbolForHistoryCard: getDenominationSymbolForHistoryCard,
  getDenominationSymbolForFailedCard: getDenominationSymbolForFailedCard,
  getOriginalBalanceDenominationSymbol: getOriginalBalanceDenominationSymbol,
  getBalanceDenominationSymbol: getBalanceDenominationSymbol,
  getDefaultBalance: getDefaultBalance,
  giftDataToCard: giftDataToCard,
  giftDataThemeToTheme: giftDataThemeToTheme,
  giftDataMediaProviderDetails: giftDataMediaProviderDetails$1,
  giftDataMediaToMedia: giftDataMediaToMedia$1,
  giftDataToHistoryCard: giftDataToHistoryCard,
  giftDataToHistoryGiftCard: giftDataToHistoryGiftCard,
  giftDataSkuThemeToSkuTheme: giftDataSkuThemeToSkuTheme,
  giftDataToFailedCard: giftDataToFailedCard,
  giftDataToGiftCard: giftDataToGiftCard
};

var Exchange = {};

var CustomTheme = {};

var Signup = {};

export {
  Card ,
  HomePageSlide ,
  SideBar ,
  $$Notification ,
  ProductDescription ,
  WalletCard ,
  BoardMember ,
  AboutSlide ,
  GiftCard ,
  Exchange ,
  CustomTheme ,
  Signup ,
}
/* Util Not a pure module */
