import Script from 'next/script';
import { cookieName } from 'auth/user/AuthUtils.bs';
import * as snippet from '@segment/snippet';
import { injectHotjarSnippet } from 'utils/injectHotjarSnippet';
import { getSessionIdToken } from 'api/helpers';
import { gtagMap } from './common/GtagMeasurement';
declare global {
  interface Window {
    sardineContext: any;
    _Sardine: any;
  }
}

export const Scripts = (): JSX.Element => (
  <>
    <Script
      id="onetrust-js"
      strategy="beforeInteractive"
      src="https://cdn-au.onetrust.com/scripttemplates/otSDKStub.js"
      type="text/javascript"
      charSet="UTF-8"
      data-domain-script={process.env.NEXT_PUBLIC_ONETRUST_SCRIPT_DOMAIN}
    />
    <Script
      id="onetrust-consent-wrapper"
      strategy="beforeInteractive"
      src="https://cdn.jsdelivr.net/npm/@segment/analytics-consent-wrapper-onetrust@latest/dist/umd/analytics-onetrust.umd.js"
    />
    <Script
      id="onetrust-optanon-wrapper"
      type="text/javascript"
      strategy="beforeInteractive"
      // Regex below grabs the body of the function as a string
      dangerouslySetInnerHTML={{
        __html: `function OptanonWrapper() {}`,
      }}
    />
    <Script
      id="cookie-destinations-checker"
      type={'text/javascript'}
      async
      dangerouslySetInnerHTML={{
        __html: `
                window.onerror = function(e) {
                  e.toString() === 'Script error.' 
                    ? window.isCookieDestinationsDefined = true
                    : (() => void 0)();
                }
              `,
      }}
    />

    {process.env.NEXT_PUBLIC_APP_ENV === 'staging' && (
      <Script
        id="hotjar-snippet"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `${injectHotjarSnippet()}`,
        }}
      />
    )}

    <Script
      id="segment-snippet"
      data-ot-ignore
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{
        __html: snippet.min({
          apiKey: process.env.NEXT_PUBLIC_SEGMENT_KEY,
          page: false,
          // @note: Segment library load irrespective of customer accept/reject cookie popup for UK region
          load: false,
        }),
      }}
    />
    <Script
      id="segmentLoad"
      dangerouslySetInnerHTML={{
        __html: `
        const measurementId = '${gtagMap[process.env.NEXT_PUBLIC_APP_COUNTRY_CODE]}';

        const getQueryParam = (param) => {
          const urlParams = new URLSearchParams(window.location.search);
          return urlParams.get(param);
        };

        const getGATrackingInfo = async (tagId, types) => {
          if (!Array.isArray(types)) return null;

          return new Promise((resolve) => {
            const maxRetries = 5;
            const checkInterval = 100;
            let attempts = 0;

            const intervalId = setInterval(() => {
              attempts++;

              if (typeof window.gtag === 'function') {
                clearInterval(intervalId);

                const trackingPromises = types.map(
                  (type) =>
                    new Promise((resolveType) => {
                      let resolved = false;

                      const timeoutId = setTimeout(() => {
                        if (!resolved) {
                          resolved = true;
                          resolveType({
                            type,
                            value: null,
                            status: "rejected",
                            reason: "timeout",
                          });
                        }
                      }, 1000);

                      window.gtag('get', tagId, type, (trackId) => {
                        if (!resolved) {
                          resolved = true;
                          clearTimeout(timeoutId);
                          resolveType({
                            type,
                            value: trackId ? String(trackId) : null,
                            status: trackId ? "fulfilled" : "rejected",
                          });
                        }
                        return undefined;
                      });
                    })
                );

                Promise.allSettled(trackingPromises).then((results) => {
                  const trackingData = results.reduce((acc, result) => {
                    if (result.status === "fulfilled" && result.value.value) {
                      acc[result.value.type] = result.value.value;
                    }
                    return acc;
                  }, {});
                  resolve(trackingData);
                });
              } else if (attempts >= maxRetries) {
                clearInterval(intervalId);
                  console.log("Object window.gtag not found after" + maxRetries + "attempts.");
                resolve({});
              }
            }, checkInterval);
          });
        };

        const getCookie = (name) => {
          const cookies = document.cookie.split(';');
          for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
              return cookie.substring(name.length + 1);
            }
          }
          return null;
        };

        const setCookie = (name, value, days) => {
          let expires = "";
          if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
          }
          document.cookie = name + "=" + (value || "") + expires + "; path=/";
        };

        const gatherTrackingInfo = async (measurementId) => {
          const userAgent = navigator.userAgent;
          const fbp = getCookie("_fbp");
          const fbc = getCookie("_fbc");
          const gclid = getQueryParam("gclid") || getCookie("gclid");
          const msclkid = getQueryParam("msclkid") || getCookie("msclkid");
          const linkedinId = getCookie("li_fat_id");
          const globalMeasurementId = 'G-9V8NCQWXNE';

          let ga4Info = {};
          let globalGa4Info = {};

          if (getCookie("gclid") !== gclid && gclid) {
            setCookie("gclid", gclid, 365);
          }
          if (getCookie("msclkid") !== msclkid && msclkid) {
            setCookie("msclkid", msclkid, 365);
          }

          if (measurementId) {
            ga4Info = await getGATrackingInfo(measurementId, [
              "client_id",
              "session_id",
              "session_number",
            ]);
            }

          if (globalMeasurementId) {
            const globalSessionData = await getGATrackingInfo(globalMeasurementId, [
              "session_id",
            ]);
            globalGa4Info = {
              ...(globalSessionData.session_id && {
                global_session_id: globalSessionData.session_id,
              }),
            };
          }

          return {
            ga4: {
              ...ga4Info,
              ...globalGa4Info,
            },
            google_ads: { ...(gclid && { gclid: gclid }) },
            fb: {
              ...(fbc && { fbc: fbc }),
              ...(fbp && { fbp: fbp }),
              ...(userAgent && { user_agent: userAgent }),
            },
            linkedin: { ...(linkedinId && { li_fat_id: linkedinId }) },
            microsoft_ads:{ ...(msclkid && { msclkid }) }
          };
        };

        const pathname = window.location.pathname;
        const integrations ={}
          if (!pathname.startsWith('/business/')) {
            integrations['HubSpot'] = false;
          }
        withOneTrust(analytics).load('${process.env.NEXT_PUBLIC_SEGMENT_KEY}', { integrations: integrations });
        analytics.addSourceMiddleware(({ payload, next }) => {
          const oneTrustActiveGroupArray = window.OnetrustActiveGroups.split(',').filter(e => e.startsWith('C'));
          if(oneTrustActiveGroupArray){
            const googlePreferences = {
              ad_user_data: oneTrustActiveGroupArray.includes('C0004') ? 'granted' : 'denied',
              ad_personalisation: oneTrustActiveGroupArray.includes('C0004') ? 'granted' : 'denied',
              ad_storage: oneTrustActiveGroupArray.includes('C0004') ? 'granted' : 'denied',
              analytics_storage: oneTrustActiveGroupArray.includes('C0002') ? 'granted' : 'denied',
            };
          payload.obj.context = {...payload.obj.context, ...googlePreferences}
          }
          next(payload)
        });

        analytics.addSourceMiddleware(async ({ payload, next }) => {
          if (payload.obj.type === 'identify') {
            const trackingInfo = await gatherTrackingInfo(measurementId);

            payload.obj.traits = {
              ...payload.obj.traits,
              marketing_data: trackingInfo,
            };
          }
          next(payload);
        });

        analytics.ready(() => {
          if (measurementId) {
            const maxRetries = 50;
            const checkInterval = 100;
            let attempts = 0;

            const intervalId = setInterval(() => {
              attempts += 1;

              if (typeof window.gtag === 'function') {
                window.gtag('config', measurementId);
                clearInterval(intervalId);
                return;
              }

              if (attempts >= maxRetries) {
                clearInterval(intervalId);
                return;
              }
            }, checkInterval);
          }
        });
    `,
      }}
    />
    <Script id="shirl" async src="https://static.srcspot.com/libs/shirl.js" type="application/javascript" />
    <Script
      id="global-env"
      async
      dangerouslySetInnerHTML={{
        __html: `
              window.GLOBAL = {
                env: {
                  CLIENT_COOKIE_STORAGE_NAME: "${cookieName}",
                  CLIENT_ENV: "${process.env.NODE_ENV}",
                  CLIENT_APP_ENV: "${process.env.NEXT_PUBLIC_APP_ENV}",
                  CLIENT_ENCRYPTION_KEY: "${process.env.NEXT_PUBLIC_KEY}",
                  CLIENT_GRAPHQL_API_URL: "${process.env.NEXT_PUBLIC_BFF_ENDPOINT}",
                  CLIENT_PREZZEE_SERVER_BASE_URL: "${process.env.NEXT_PUBLIC_PREZZEE_SERVER_URL}",
                  CLIENT_REGION: "${process.env.NEXT_PUBLIC_APP_COUNTRY_CODE}",
                  CLIENT_SEGMENT_API_KEY: "${process.env.NEXT_PUBLIC_SEGMENT_KEY}"
                },
              };
                `,
      }}
    />
    {process.env.NEXT_PUBLIC_SARDINE_CLIENT_ID && (
      <Script
        id="sardine-load"
        src={`https://${process.env.NEXT_PUBLIC_SARDINE_API_URL}/assets/loader.min.js`}
        onLoad={() => {
          window.sardineContext = window._Sardine.createContext({
            clientId: process.env.NEXT_PUBLIC_SARDINE_CLIENT_ID,
            sessionKey: getSessionIdToken({ updateConfig: false }),
            flow: location.pathname,
            environment: process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 'production' : 'sandbox',
            parentElement: document.body,
            apiSubdomain: process.env.NEXT_PUBLIC_SARDINE_API_URL,
            pixelSubdomain: process.env.NEXT_PUBLIC_SARDINE_PIXEL_URL,
            // called when sardine generates/restores deviceID for given device.
            onDeviceResponse: function (data) {},
          });
        }}
      />
    )}
  </>
);
