import { saveTokenCookie, cookieName } from 'auth/user/AuthUtils.bs';
import Cookies from 'js-cookie';
import * as Segment from 'bindings/Segment.bs';
import * as API from 'api/queries/index';

import { createContext, Fragment, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import MfaDialog from 'components/MfaDialog';
import { AccountDetails, Wallet } from 'models/customer.model';
import type { RegisterNewUser } from 'common/types';
import { User } from 'common/types';
import { decryptUserCookie } from 'utils/authUtils';
import useSegment from 'hooks/useSegment';
import { useAccountDetailsQuery } from 'hooks/useAccountDetailsQuery';
import { useQueryClient } from '@tanstack/react-query';
import logger from 'utils/logger';
import { getSessionIdToken } from 'api/helpers';

export type MfaState = {
  required: boolean;
  callback?: ((customer?: AccountDetails) => Promise<void>) | ((customer?: AccountDetails) => void);
  fromWalletMfe?: boolean;
  loading?: boolean;
};
export interface AuthContextType {
  user?: User;
  loading: boolean;
  mfa: MfaState;
  error?: Error;
  login: (email: string, password: string, callback?: MfaState['callback']) => Promise<void>;
  signUp: (payload: RegisterNewUser, signUpInstanceId: string, emailSubscription: boolean) => Promise<void>;
  logout: () => Promise<void>;
  reset: () => void;
  updateMfa: (mfaState: MfaState) => void;
  getWallet: () => Promise<Wallet> | undefined;
}

const getWallet = async (): Promise<Wallet> => {
  const customer = await API.getCustomerData();
  if (!customer) {
    return;
  }
  return customer.wallet;
};
export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
  const { signedIn, signedUp } = useSegment();
  const [user, setUser] = useState<User>();
  const [error, setError] = useState<Error>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [mfa, setMfa] = useState<MfaState>({ required: false, callback: undefined, loading: false });
  const [accessToken, setAccessToken] = useState<string>(null);

  const [callbackState, setCallback] = useState<(customer: AccountDetails) => void>(undefined);

  const { data, status, error: customerError } = useAccountDetailsQuery({ enabled: !!accessToken, retry: false });
  const client = useQueryClient();

  const countRef = useRef(0);
  let cachedData = null;
  /**
   * Transforms customer data into user data (Mainly to support old auth context).
   * @param customer customer data from the server
   * @returns user data to be stored in the context
   */
  function customerToUser(customer: AccountDetails): User {
    return {
      uid: customer?.customerUid || null,
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      phoneNumber: customer?.phone,
      email: customer?.email,
    };
  }
  const getCustomerWallet = () => {
    return getWallet().then(wallet => wallet);
  };

  useEffect(() => {
    if (status === 'success') {
      setLoading(false);
      if (data) {
        logger.info(
          {
            message: 'Customer data fetched successfully',
            context: 'AuthContext',
          },
          null,
          ['local', 'development', 'staging']
        );
        if (!user) {
          const user = customerToUser(data as AccountDetails);
          setUser(user);
          saveTokenCookie({
            userDetails: user,
            token: accessToken,
            masquerade: false,
          });
          setError(null);
        }
        if (callbackState) {
          logger.info(
            {
              message: 'Customer data fetched successfully and callback is run',
              context: 'AuthContext',
            },
            null,
            ['local', 'development', 'staging']
          );
          callbackState(data as AccountDetails);
          setCallback(undefined);
        }
      }
    }
  }, [data, status, callbackState]);

  useEffect(() => {
    let timeout;
    if (customerError) {
      if (customerError.message === API.customerDataErrors.mfaRequired) {
        setMfa(prevState => ({ ...prevState, required: true }));
      } else if (customerError.message === API.customerDataErrors.tokenMissing) {
        //AccessToken exists but may not be set on the cookie yet. This is in place to handle that unlikely race conditions
        if (accessToken && countRef.current < 3) {
          logger.info({
            context: 'AuthContext',
            retries: countRef.current,
            message: 'Access token exists in state but no cookie. Retrying...',
          });
          countRef.current++;
          timeout = setTimeout(() => {
            //Timeout used to give time for the cookie to be set
            client.resetQueries({ queryKey: ['customer'] });
          }, 300);
        } else {
          //No access token exists or exceeded number of retries
          logger.info({
            context: 'AuthContext',
            retries: countRef.current,
            message: 'Access token exists in state but no cookie. Retry Failed',
          });
          reset();
          setError(customerError);
        }
      } else {
        reset();
        setError(customerError);
        logger.error({
          message: 'Customer API error',
          err: customerError,
          context: 'AuthContext',
        });
      }
      setLoading(false);
    }
    return clearTimeout(timeout);
  }, [customerError]);

  useEffect(() => {
    if (accessToken === undefined) {
      logger.info(
        {
          context: 'AuthContext',
          message: 'Access token is undefined. Resetting queries',
        },
        null,
        ['local', 'development', 'staging']
      );
      client.resetQueries({ queryKey: ['customer'] });
    }
  }, [accessToken]);

  const userSignIn = (accessToken: string, callback?: (customer: AccountDetails) => void) => {
    if (accessToken) {
      saveTokenCookie({
        userDetails: null,
        token: accessToken,
        masquerade: false,
      });
      //This checks for MFA and sets the callback if needed. Ideally this is removed once MFA is checked on account details.
      API.getAccountDetails()
        .then(() => {
          setCallback(() => callback);
          setAccessToken(accessToken);
        })
        .catch((error: Error) => {
          if (error.message == API.customerDataErrors.mfaRequired) {
            setMfa({ required: true, callback });
          } else {
            throw error;
          }
        });
    } else {
      reset();
      setError(new Error('Missing token on sign in'));
      logger.error({
        message: 'error: missing token on sign in',
        context: 'AuthContext',
      });
    }
  };

  const login = async (email: string, password: string, callback?: MfaState['callback']) => {
    setLoading(true);
    setMfa({ required: false, callback });
    try {
      const loginRes = await API.login(email, password);
      if (!loginRes?.accessToken) {
        throw new Error('Access token not found on login response');
      }
      userSignIn(loginRes?.accessToken, (customer: AccountDetails) => {
        callback ? callback(customer) : null;
        Segment.identifyWithContext(customer?.customerUid, {
          user_type: 'consumer',
          first_name: customer?.firstName,
          last_name: customer?.lastName,
          phone: customer?.phone,
          email: customer?.email,
          id: customer?.customerUid,
          username: customer?.email,
        });

        signedIn({
          username: customer?.email,
          signin_type: 'gift_open',
        });
        window.sardineContext?.updateConfig({
          userIdHash: customer?.customerUid,
          flow: 'user_login',
        });
      });
    } catch (err) {
      setError(err);
      logger.error({
        message: 'Login error',
        err: err,
        context: 'AuthContext',
      });
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const handleMfaSubmit = async (code: string) => {
    setMfa(prevState => ({ ...prevState, loading: true }));

    try {
      await API.validateMfaToken(code);
      client.invalidateQueries({ queryKey: ['customer'] });
      const userCookie = Cookies.get(cookieName);

      if (userCookie) {
        const decryptedUser = decryptUserCookie(userCookie);

        userSignIn(decryptedUser.token, (customer: AccountDetails) => {
          mfa.callback(customer);
          Segment.identifyWithContext(customer?.customerUid, {
            user_type: 'consumer',
            first_name: customer?.firstName,
            last_name: customer?.lastName,
            phone: customer?.phone,
            email: customer?.email,
            id: customer?.customerUid,
            username: customer?.email,
          });

          setMfa({ required: false, callback: undefined, loading: false });
        });
      }
    } catch (err) {
      setError(err);
      setMfa(prevState => ({ ...prevState, loading: false }));
    }
  };

  const signUp = async (payload: RegisterNewUser, signUpInstanceId: string, emailSubscription: boolean) => {
    const signUpResponse = await API.register(payload);
    userSignIn(signUpResponse.accessToken, (customer: AccountDetails) => {
      Segment.identifyWithContext(customer?.customerUid, {
        user_type: 'consumer',
        signup_type: 'gift_open',
        first_name: customer?.firstName,
        last_name: customer?.lastName,
        phone: customer?.phone,
        email: customer?.email,
        id: customer?.customerUid, // TODO: potentially update this field to be user_id to match Segment`s schema
        username: customer?.email,
        email_subscription_braze: emailSubscription ? 'subscribed' : 'unsubscribed', // NOTE: temporary field.
      });

      signedUp({
        created_at: new Date().toISOString(),
        user_type: 'consumer',
        signup_type: 'gift_open',
        signup_instance_id: signUpInstanceId,
        first_name: customer?.firstName,
        last_name: customer?.lastName,
        phone: customer?.phone,
        email: customer?.email,
        username: customer?.email,
      });
    });
  };

  const reset = () => {
    Cookies.remove(cookieName);
    Cookies.remove('sessionid');
    Segment.reset(undefined);
    setAccessToken(undefined);
    setUser(undefined);
    setError(null);
    client.resetQueries({ queryKey: ['customer'] });
    const sessionId = getSessionIdToken({ updateConfig: false, resetToken: true });
    window.sardineContext?.updateConfig({
      sessionKey: sessionId,
      userIdHash: null,
      flow: 'user_logout',
    });

    countRef.current = 0;
  };

  async function logout() {
    try {
      await API.signOut();
      reset();
    } catch (error) {
      logger.error({
        message: 'error: unable to sign out user',
        err: error,
        journey: 'Sign Out',
        context: 'AuthContext',
      });
    }
  }

  useEffect(() => {
    const userCookie = Cookies.get(cookieName);

    if (userCookie) {
      const decryptedUser = decryptUserCookie(userCookie);
      if (decryptedUser.token) {
        // user cookie found and token was valid
        setUser(decryptedUser.userDetails);
        setAccessToken(decryptedUser.token);
      } else {
        // user cookie found and token was invalid so reset auth state
        reset();
        setLoading(false);
        logger.warn({
          message: 'error: invalid token on user cookie',
          context: 'AuthContext',
        });
      }
    } else {
      client.resetQueries({ queryKey: ['customer'] });
      setLoading(false);
    }
  }, []);

  const memoedValue = useMemo<AuthContextType>(
    () => ({
      user,
      loading,
      error,
      login,
      signUp,
      logout,
      reset,
      mfa,
      updateMfa: setMfa,
      getWallet: getCustomerWallet,
    }),
    [user, loading, error, login, mfa]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      <Fragment>
        {children}
        {mfa.required && (
          <MfaDialog
            onSubmit={handleMfaSubmit}
            onSignOut={() => setMfa({ required: false, callback: undefined })}
            isLoading={mfa.loading}
          />
        )}
      </Fragment>
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
